import Link from "next/link";
import React from "react";
import Layout from "../components/Layout";
import FrontHeader from "../components/FrontHeader";

const Custom404 = () => {
  return (
    <Layout>
      <FrontHeader />
      <div
        className=" position-fixed d-flex align-items-center justify-content-center flex-column text-center"
        style={{
          backgroundImage: "url(/images/page404.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          width: "100vw",
          height: "100svh",
        }}
      >
        <div
          className="d-center flex-column"
          style={{
            position: "absolute",
            top: "80px",
          }}
        >
          <img src="/images/img404.png" className="image-404" />

          <div
            style={{
              background: "rgba(255,255,255,0.50)",
              padding: 8,
              width: "min(100%, 375px)",
              fontSize: 21,
              fontWeight: "bold",
              backdropFilter: "blur(5px)",
              borderRadius: 10,
            }}
          >
            Diantre ! Vous semblez égaré, cette page n'existe pas sur
            goperceval.fr
          </div>
        </div>
        <div
          className="position-absolute btn-return-404"
          style={{
            bottom: "12%",
          }}
        >
          <Link href="/">
            <a className="btn btn-secondary d-center">Revenir à l'accueil</a>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Custom404;
